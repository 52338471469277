import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { FaCopy } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";

const GameMenu = () => {
  const { code } = useParams();
  const [copied, setCopied] = useState(false);

  return (
    <div className="h-screen w-full flex justify-center items-center bg-background text-right font-sans">
      <div className="max-w-2xl w-full  flex flex-col justify-center items-center gap-5 bg-secondaryButton p-16 rounded-lg">
        <h1 className="text-4xl text-white font-bold pb-10">نبلش اللعب؟</h1>
        <div className="flex flex-row gap-4 justify-center items-center">
          <div className="flex flex-row gap-5 bg-behindButton rounded-md p-4">
            <CopyToClipboard
              text={code}
              onCopy={() => {
                setCopied(true);
                toast.success("تم نسخ الكود");
              }}
            >
              <button className="  px-4  border-r-2 border-white">
                {copied ? (
                  <FaCheck className="text-white text-2xl" />
                ) : (
                  <FaCopy className="text-white text-2xl" />
                )}
              </button>
            </CopyToClipboard>
            <h2 className="text-2xl text-white font-bold ">{code}</h2>
          </div>

          <h2 className="text-2xl text-white font-semibold ">
            الكود الخاص باللعبة
          </h2>
        </div>
        <Link
          to={`/${code}`}
          className="w-full h-16 bg-mainButton rounded-lg flex justify-center items-center text-white text-2xl font-bold"
        >
          بلش اللعب
        </Link>
      </div>
    </div>
  );
};

export default GameMenu;
