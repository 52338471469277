// AddQuestionForm.jsx
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { createCutsomQuestion } from "../api/custom-mode";
import DragAndDropImage from "../components/DragAndDropImage";
import { isMostlyArabic } from "../funcs/isMostlyArabic";

export default function AddQuestionForm() {
  const { id, name, image } = useParams(); // modeId
  const [webPassword, setWebPassword] = useState("");
  const [shouldClear, setShouldClear] = useState(false);
  const [questionInfo, setQuestionInfo] = useState({
    question: "",
    answer: "",
    points: 100, // default
  });

  // We'll store the actual File object in these states
  const [questionFile, setQuestionFile] = useState(null);
  const [answerFile, setAnswerFile] = useState(null);

  // We track the text direction separately for question & answer:
  const [questionDirection, setQuestionDirection] = useState("rtl");
  const [answerDirection, setAnswerDirection] = useState("rtl");

  const { mutate, isLoading } = useMutation({
    mutationKey: ["create-custom-question", id],
    mutationFn: () =>
      createCutsomQuestion(id, webPassword, {
        ...questionInfo,
        questionFile, // pass the File object
        answerFile,
      }),
    onSuccess: () => {
      toast.success("تم إضافة السؤال بنجاح");
    },
    onError: (error) => {
      const message =
        error?.response?.data?.message || "حدث خطأ أثناء إضافة السؤال";
      toast.error(message);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!webPassword.trim()) {
      return toast.error("يرجى إدخال كلمة المرور");
    }
    if (!questionInfo.question.trim()) {
      return toast.error("يرجى إدخال السؤال");
    }
    if (!questionInfo.answer.trim()) {
      return toast.error("يرجى إدخال الجواب");
    }
    mutate();
  };

  const handleReset = () => {
    setWebPassword("");
    setQuestionInfo({ question: "", answer: "", points: 100 });
    setQuestionFile(null);
    setAnswerFile(null);
    setQuestionDirection("rtl");
    setAnswerDirection("rtl");
    setShouldClear(true);
  };

  // Called whenever user types in question
  const handleQuestionChange = (value) => {
    setQuestionInfo({ ...questionInfo, question: value });
    setQuestionDirection(isMostlyArabic(value) ? "rtl" : "ltr");
  };

  // Called whenever user types in answer
  const handleAnswerChange = (value) => {
    setQuestionInfo({ ...questionInfo, answer: value });
    setAnswerDirection(isMostlyArabic(value) ? "rtl" : "ltr");
  };

  return (
    <div className="max-w-5xl mx-auto p-4 space-y-6  text-right">
      <div className="flex flex-row items-center gap-4">
        <img
          src={
            "https://bucketeer-a1ba57ee-9d54-4a0d-892d-6fb4c931b953.s3.eu-west-1.amazonaws.com/" +
            image +
            "-resized.jpg"
          }
          alt={name}
          className="w-16 h-16 rounded-md"
        />
        <h1 className="text-2xl font-bold text-dark">{name}</h1>
      </div>
      <h2 className="text-2xl font-bold text-dark mb-4">إضافة سؤال جديد</h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Password */}
        <div className="space-y-1">
          <label className="block font-semibold">كلمة المرور:</label>
          <input
            type="password"
            value={webPassword}
            onChange={(e) => setWebPassword(e.target.value)}
            className="w-full p-3 rounded border border-gray-300 focus:outline-none"
            style={{ direction: "rtl", textAlign: "right" }}
          />
        </div>

        {/* Question + Image side by side on large screens */}
        <div className="grid md:grid-cols-2 gap-4">
          {/* Question Image */}
          <div className="space-y-1">
            <p className="font-semibold">صورة السؤال (اختياري):</p>
            <DragAndDropImage
              shouldClear={shouldClear}
              setShouldClear={setShouldClear}
              onFileSelected={setQuestionFile}
              label="اسحب وأسقط أو ألصق صورة للسؤال"
            />
          </div>
          {/* Question text */}
          <div className="space-y-1">
            <label className="block font-semibold">السؤال:</label>
            <textarea
              rows={4}
              value={questionInfo.question}
              onChange={(e) => handleQuestionChange(e.target.value)}
              className="w-full p-3 rounded border border-gray-300 focus:outline-none"
              style={{
                direction: questionDirection,
                textAlign: questionDirection === "rtl" ? "right" : "left",
              }}
            />
          </div>
        </div>

        {/* Answer + Image side by side */}
        <div className="grid md:grid-cols-2 gap-4">
          {/* Answer Image */}
          <div className="space-y-1">
            <p className="font-semibold">صورة الجواب (اختياري):</p>
            <DragAndDropImage
              shouldClear={shouldClear}
              setShouldClear={setShouldClear}
              onFileSelected={setAnswerFile}
              label="اسحب وأسقط أو ألصق صورة للجواب"
            />
          </div>
          {/* Answer text */}
          <div className="space-y-1">
            <label className="block font-semibold">الجواب:</label>
            <textarea
              rows={4}
              value={questionInfo.answer}
              onChange={(e) => handleAnswerChange(e.target.value)}
              className="w-full p-3 rounded border border-gray-300 focus:outline-none"
              style={{
                direction: answerDirection,
                textAlign: answerDirection === "rtl" ? "right" : "left",
              }}
            />
          </div>
        </div>

        {/* Points as Buttons */}
        <div className="space-y-2">
          <label className="block font-semibold">النقاط:</label>
          <div className="flex flex-wrap items-center gap-3 justify-end">
            {[100, 200, 300, 400, 500].reverse().map((pts) => {
              const active = questionInfo.points === pts;
              return (
                <button
                  type="button"
                  key={pts}
                  onClick={() =>
                    setQuestionInfo({ ...questionInfo, points: pts })
                  }
                  className={`px-4 py-2 rounded-md shadow-md transition-all 
                    ${
                      active
                        ? "bg-mainButton hover:bg-mainButtonShadow text-white font-bold"
                        : "bg-gray-400 hover:bg-gray-500 text-white font-bold"
                    }
                  `}
                >
                  {pts}
                </button>
              );
            })}
          </div>
        </div>

        {/* Submit & Reset */}
        <div className="flex flex-wrap gap-4 pt-4 justify-end">
          <button
            type="submit"
            disabled={isLoading}
            className={`px-6 py-3 rounded-md font-bold transition-colors 
              text-white 
              ${
                isLoading
                  ? "bg-secondaryButtonShadow cursor-not-allowed"
                  : "bg-behindButton hover:bg-secondaryButton"
              }`}
          >
            {isLoading ? "جاري الإضافة..." : "إضافة السؤال"}
          </button>

          <button
            type="button"
            onClick={handleReset}
            className="px-6 py-3 rounded-md font-bold text-white bg-danger hover:bg-dangerShadow transition-colors"
          >
            تنظيف
          </button>
        </div>
      </form>
    </div>
  );
}
