// src/components/Question.jsx
import React, { useEffect, useState } from "react";
import MKHHeader from "../components/header/MKHHeader";
import SJHeader from "../components/header/SJHeader";
import TarbaaHeader from "../components/header/TarbaaHeader";
import TarbaaQuestion from "../components/tarbaa/Question";
import TarbaaAnswer from "../components/tarbaa/Answer";
import MKHQuestion from "../components/mkh/Question";
import SJQuestion from "../components/sj/Question";
import { useParams } from "react-router-dom";
import WhoAnswered from "../components/tarbaa/WhoAnswered";

// Import encryption utilities
import { encryptData, decryptData } from "../funcs/encryption";
import SJAnswer from "../components/sj/Answer";
import SJWhoAnswered from "../components/sj/WhoAnswered";

const Question = () => {
  const { id } = useParams();
  const [theme, setTheme] = useState("Tarbaa");
  const [game, setGame] = useState(null);
  const [ads, setAds] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showWhoAnswered, setShowWhoAnswered] = useState(false);

  // Retrieve encrypted data from localStorage and decrypt it
  useEffect(() => {
    const encryptedTheme = localStorage.getItem("theme");
    const encryptedGame = localStorage.getItem("game");
    const encryptedAds = localStorage.getItem("ads");

    if (encryptedTheme) {
      setTheme(encryptedTheme || "Tarbaa");
    }

    if (encryptedGame) {
      const decryptedGame = decryptData(encryptedGame);
      setGame(decryptedGame);
    }

    if (encryptedAds) {
      setAds(encryptedAds);
    }
  }, []);

  // Encrypt and save game data to localStorage whenever it changes
  useEffect(() => {
    if (game !== null) {
      const encryptedGame = encryptData(game);
      localStorage.setItem("game", encryptedGame);
    }
  }, [game]);

  // Optionally, encrypt and save theme if it changes (if your application allows theme changes)
  // Add similar useEffect for theme if needed

  // Determine the current question based on the `id` from params
  const question = game?.modes
    ?.find((mode) => mode.questions.find((q) => q._id === id))
    .questions.find((q) => q._id === id).question
    ? {
        ...game?.modes
          ?.find((mode) => mode.questions.find((q) => q._id === id))
          .questions.find((q) => q._id === id).question,
        custom: false,
      }
    : {
        ...game?.modes
          ?.find((mode) => mode.questions.find((q) => q._id === id))
          .questions.find((q) => q._id === id).customQuestion,
        custom: true,
      };

  useEffect(() => {
    // Prevent right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <div className="h-full w-full flex flex-col font-sans select-none ">
      {theme === "MKH" ? (
        <>
          <MKHHeader theme={theme} game={game} setGame={setGame} ads={ads} />
          <MKHQuestion
            theme={theme}
            game={game}
            setGame={setGame}
            ads={ads}
            question={question}
          />
        </>
      ) : theme === "SJ" ? (
        <>
          <SJHeader theme={theme} game={game} setGame={setGame} ads={ads} />

          {showAnswer ? (
            <>
              {showWhoAnswered ? (
                <SJWhoAnswered
                  theme={theme}
                  game={game}
                  setGame={setGame}
                  ads={ads}
                  question={question}
                  setShowAnswer={setShowAnswer}
                  setShowWhoAnswered={setShowWhoAnswered}
                />
              ) : (
                <SJAnswer
                  theme={theme}
                  game={game}
                  setGame={setGame}
                  ads={ads}
                  question={question}
                  setShowAnswer={setShowAnswer}
                  setShowWhoAnswered={setShowWhoAnswered}
                />
              )}
            </>
          ) : (
            <SJQuestion
              theme={theme}
              game={game}
              setGame={setGame}
              ads={ads}
              question={question}
              setShowAnswer={setShowAnswer}
            />
          )}
        </>
      ) : (
        <>
          <TarbaaHeader theme={theme} game={game} setGame={setGame} ads={ads} />
          {showAnswer ? (
            <>
              {showWhoAnswered ? (
                <WhoAnswered
                  theme={theme}
                  game={game}
                  setGame={setGame}
                  ads={ads}
                  question={question}
                  setShowAnswer={setShowAnswer}
                  setShowWhoAnswered={setShowWhoAnswered}
                />
              ) : (
                <TarbaaAnswer
                  theme={theme}
                  game={game}
                  setGame={setGame}
                  ads={ads}
                  question={question}
                  setShowAnswer={setShowAnswer}
                  setShowWhoAnswered={setShowWhoAnswered}
                />
              )}
            </>
          ) : (
            <TarbaaQuestion
              theme={theme}
              game={game}
              setGame={setGame}
              ads={ads}
              question={question}
              setShowAnswer={setShowAnswer}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Question;
