import { instance } from "..";

export const getGameByCode = async (code) => {
  const response = await instance.get(`/api/web/${code}`);
  return response.data;
};

export const questionShown = async (questionId, isAnswered) => {
  const response = await instance.put(`/api/web/question/shown/${questionId}`, {
    isAnswered,
  });
  return response.data;
};
