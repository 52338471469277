import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Game from "./pages/Game";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Question from "./pages/Question";
import Winning from "./pages/Winning";
import CustomModeQuestion from "./pages/CustomModeQuestion";
import GameMenu from "./pages/GameMenu";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/*",
    element: <App />,
  },
  {
    path: "/custom-mode/:id/:name/:image",
    element: <CustomModeQuestion />,
  },
  {
    path: "/game-menu/:code",
    element: <GameMenu />,
  },
  {
    path: "/:code",
    element: <Game />,
  },
  {
    path: "/question/:id",
    element: <Question />,
  },
  {
    path: "/:code/end",
    element: <Winning />,
  },
  {
    // Catch-all route to redirect invalid paths
    path: "*",
    element: <Navigate to="/" />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ToastContainer />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
