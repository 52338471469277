import React from "react";
import { FaShuffle, FaTrophy } from "react-icons/fa6";
import { IoExit } from "react-icons/io5";
import sjLogo from "../../assets/images/sj-logo.png";
import ChangeTheme from "../themes/ChangeTheme";
import { useNavigate } from "react-router-dom";

const SJHeader = ({ theme, game, setGame }) => {
  const navigate = useNavigate();
  const nextTurn = () => {
    const currentTurnIndex = game.teams.findIndex((team) => team.currentTurn);
    const nextTurnIndex =
      currentTurnIndex === game.teams.length - 1 ? 0 : currentTurnIndex + 1;

    const updatedTeams = game.teams.map((team, index) => ({
      ...team,
      currentTurn: index === nextTurnIndex,
    }));

    setGame({ ...game, teams: updatedTeams });
  };

  return (
    <>
      <div
        style={{
          background: "linear-gradient(0.25turn,#0000CD,transparent)",
          backgroundColor: "#81D8D0",
        }}
        className="w-full flex flex-row md:flex-row items-center justify-between p-2 md:pl-4 gap-1 h-[9vh] "
      >
        {/* Left Buttons */}
        <div className="flex flex-row items-center gap-2">
          <button
            className="flex items-center gap-2 text-white px-2 py-1 lg:px-3 lg:py-2 rounded-xl border-white border-2 font-bold uppercase text-sm md:text-base shadow hover:shadow-lg transition duration-150"
            type="button"
            onClick={() => navigate("/")}
          >
            <IoExit className="w-5 h-5 md:w-6 md:h-6 transform rotate-180" />
            <h1 className="hidden md:block">الخروج</h1>
          </button>
          {/* End Game Button */}
          <button
            className="flex items-center gap-2 text-white px-2 py-1 lg:px-3 lg:py-2 rounded-xl border-white border-2 font-bold uppercase text-sm md:text-base shadow hover:shadow-lg transition duration-150"
            type="button"
            onClick={() => navigate(`/${game?.codeInfo?.code || "old"}/end`)}
          >
            <FaTrophy className="w-5 h-5 md:w-6 md:h-6" />
            <h1 className="hidden lg:block">إنهاء اللعبة</h1>
          </button>
          <ChangeTheme theme={theme} />
        </div>

        {/* Game Name */}
        <div className="text-white text-lg md:text-xl font-bold text-center ">
          {game?.name}
        </div>

        {/* Right Side */}
        <div className="flex flex-row items-center gap-2 ">
          {/* Skip Turn Button */}
          <button
            className="flex items-center gap-2 text-white px-2 py-1 lg:px-3 lg:py-2 rounded-full border-white border-2 text-sm md:text-base"
            onClick={nextTurn}
          >
            <FaShuffle className="w-3 h-3 md:w-4 md:h-4" />
            <h1>
              دور فريق: {game?.teams?.find((team) => team.currentTurn)?.name}
            </h1>
          </button>

          {/* Logo */}
          <img
            className="h-10 w-10  lg:h-16 lg:w-16 object-contain text-white"
            src={sjLogo}
            alt="تربع"
          />
        </div>
      </div>
    </>
  );
};

//81D8D0

export default SJHeader;
