// src/components/tarbaa/WhoAnswered.jsx
import React from "react";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { HiPhone } from "react-icons/hi2";
import { TbSquareHalf } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

// Import encryption utilities
import { encryptData } from "../../funcs/encryption";
import { useMutation } from "@tanstack/react-query";
import { questionShown } from "../../api/game";

const SJWhoAnswered = ({ game, question, setShowWhoAnswered }) => {
  const navigate = useNavigate();

  const questionInGame = question?.custom
    ? game.modes
        .find((mode) =>
          mode.questions.find(
            (q) => (q.question?._id || q?.customQuestion?._id) === question?._id
          )
        )
        .questions.find(
          (q) => (q?.question?._id || q?.customQuestion?._id) === question?._id
        ).customQuestion
    : game.modes
        .find((mode) =>
          mode.questions.find(
            (q) => (q.question?._id || q?.customQuestion?._id) === question?._id
          )
        )
        .questions.find(
          (q) => (q?.question?._id || q?.customQuestion?._id) === question?._id
        ).question;

  const checkIfAllQuestionsAnswered = () => {
    let answered = true;
    game?.modes?.forEach((mode) => {
      mode.questions.forEach((q) => {
        if (!q.question?.answered && !q.customQuestion?.answered) {
          answered = false;
        }
      });
    });
    return answered;
  };

  const nextTurn = () => {
    const currentTurnIndex = game.teams.findIndex((team) => team.currentTurn);
    const nextTurnIndex =
      currentTurnIndex === game.teams.length - 1 ? 0 : currentTurnIndex + 1;

    const updatedTeams = game.teams.map((team, index) => ({
      ...team,
      currentTurn: index === nextTurnIndex,
    }));

    // Encrypt and save updated game data
    localStorage.setItem("game", encryptData({ ...game, teams: updatedTeams }));
  };

  const { mutate } = useMutation({
    mutationKey: ["questionShown"],
    mutationFn: ({ questionId, isAnswered }) =>
      questionShown(questionId, isAnswered),
  });

  const handleQuestionShown = (hasBeenAnswered) => {
    mutate({
      questionId: questionInGame?._id,
      isAnswered: hasBeenAnswered,
    });
  };

  return (
    <>
      <div className="w-full h-[90vh] flex flex-col sm:flex-row pl-10 pr-2 py-10 lg:py-20">
        <div className="relative h-3/4 w-full sm:w-5/6 sm:h-full text-xl lg:text-3xl font-bold text-dark border-[6px] border-[#0000CD]  rounded-[50px]">
          <div className=" w-full flex justify-center items-end h-1/6">
            <div className="text-black font-semibold text-5xl">
              منو جاوب صح؟
            </div>
          </div>
          <div className="h-5/6 w-full flex justify-center items-center p-2 px-4 lg:px-10 text-white  ">
            <div className="flex-wrap flex justify-center items-center">
              {game?.teams.map((team, index) => (
                <div className="w-1/2 h-1/4 p-2 " key={index}>
                  <button
                    onClick={() => {
                      if (question?.points > 0) {
                        game.teams[index].points += question?.points;
                        questionInGame.answered = true;
                        // Encrypt and save updated game data
                        localStorage.setItem("game", encryptData({ ...game }));
                      }
                      handleQuestionShown(true);
                      nextTurn();
                      if (checkIfAllQuestionsAnswered()) {
                        navigate(`/${game?.codeInfo?.code || "old"}/end`);
                      } else {
                        navigate(`/${game?.codeInfo?.code || "old"}`);
                      }
                    }}
                    className="p-6 w-full bg-[#00009e] rounded-full hover:bg-secondaryButtonShadow flex flex-row justify-center items-center"
                  >
                    <div className="h-full w-3/4 text-sm md:text-xl lg:text-2xl font-bold flex justify-center items-center overflow-hidden">
                      <h1 className="text-white text-center">{team?.name}</h1>
                    </div>
                  </button>
                </div>
              ))}
              <div className="w-full h-1/4 p-2 ">
                <button
                  onClick={() => {
                    questionInGame.answered = true;
                    // Encrypt and save updated game data
                    localStorage.setItem("game", encryptData(game));
                    nextTurn();
                    handleQuestionShown(false);
                    if (checkIfAllQuestionsAnswered()) {
                      navigate(`/${game?.codeInfo?.code || "old"}/end`);
                    } else {
                      navigate(`/${game?.codeInfo?.code || "old"}`);
                    }
                  }}
                  className="w-full h-full  p-2 lg:p-4 bg-gray-500 rounded-full hover:bg-gray-600"
                >
                  محد جاوب
                </button>
              </div>
            </div>
          </div>

          <div className="absolute  bottom-0   transform -translate-x-1/2 translate-y-1/2 right-0  text-white rounded-lg  text-sm lg:text-xl ">
            <button
              className="bg-red-700 rounded-full hover:bg-red-800 text-white font-bold py-3 px-4 md:px-10 text-sm md:text-xl"
              onClick={() => {
                setShowWhoAnswered(false);
              }}
            >
              الجواب
            </button>
          </div>
        </div>
        <div className=" portrait:h-1/4 portrait:w-full  h-1/4 w-full  sm:w-1/4 sm:h-full flex-row sm:flex-col portrait:flex-row flex justify-center items-center text-white text-4xl sm:pl-4 sm:gap-2 portrait:pt-6 pt-6 sm:pt-0">
          {game?.teams.map((team, index) => (
            <div
              className="w-1/4 h-full sm:w-full sm:h-1/4 px-[1px] lg:p-2"
              key={index}
            >
              <div
                className={`portrait:flex-col  w-full h-full flex flex-col sm:flex-row justify-center items-center`}
              >
                <div className="h-1/2 w-full sm:w-2/3 sm:h-full text-sm md:text-lg lg:text-xl font-bold ">
                  <div
                    className={`h-1/2 flex items-center justify-center overflow-hidden bg-[#00009e] rounded-full  ${
                      team.currentTurn === true && "border-4 border-gray-400"
                    }`}
                  >
                    <h1 className="text-white text-center">{team?.name}</h1>
                  </div>
                  <div className="portrait:w-full portrait:h-1/2 w-full h-1/2 flex justify-center items-center p-1 gap-1 lg:gap-3">
                    {team?.powerUps.map((powerUp, index) => (
                      <button
                        disabled
                        className="rounded-full border-[4px] border-[#00009e] text-[#00009e]   p-1   lg:p-2  text-sm  md:text-lg xl:text-xl disabled:border-gray-400  disabled:text-gray-400"
                        key={index}
                      >
                        {powerUp?.name === "one" ? (
                          <FaMoneyBillTransfer />
                        ) : powerUp?.name === "two" ? (
                          <HiPhone />
                        ) : (
                          <TbSquareHalf />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SJWhoAnswered;
