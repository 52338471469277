import React, { useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";
import { FaClockRotateLeft, FaMoneyBillTransfer } from "react-icons/fa6";
import { HiPhone } from "react-icons/hi2";
import { TbSquareHalf } from "react-icons/tb";
import { useStopwatch } from "react-timer-hook";
import textSizeBasedOnLength from "../../funcs/textResizer";

const SJAnswer = ({
  game,
  ads,
  question,
  setShowAnswer,
  setShowWhoAnswered,
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const { seconds, minutes, isRunning, start, pause, reset } = useStopwatch({
    autoStart: true,
  });

  const modeName = game?.modes?.find((mode) =>
    mode.questions.find(
      (q) => (q?.question?._id || q?.customQuestion?._id) === question?._id
    )
  )?.name;

  return (
    <>
      <div className="w-full h-[90vh] flex flex-col portrait:flex-col sm:flex-row portrait:py-10 pl-10 pr-2 py-10 lg:py-20">
        <div
          className={`portrait:w-full portrait:h-3/4 relative h-3/4 w-full sm:w-5/6 sm:h-full ${textSizeBasedOnLength(
            question?.answer
          )} font-bold text-dark border-[6px] border-[#0000CD] rounded-[50px] pt-4 sm:pt-6 md:pt-8`}
        >
          <div className="h-2/6 md:h-1/6 w-full flex justify-center items-top p-2  px-4 md:px-10 overflow-scroll">
            <h1 style={{ direction: "rtl", textAlign: "right" }}>
              {question?.answer}
            </h1>
          </div>
          <div className="h-4/6 md:h-5/6 w-full flex justify-center items-start px-4 pb-4 md:p-10">
            <button
              onClick={() => {
                setFullScreen(true);
              }}
              className="h-full"
            >
              <img
                className="h-full object-contain"
                src={question?.answerImage || question?.questionImage}
                alt={question?.answer}
              />
            </button>
          </div>
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-2/3 w-11/12 sm:w-full flex flex-row justify-between items-center bg-transparent">
            {/* Left Section */}
            <div className="w-1/3 flex justify-center items-center">
              <div
                className={`hidden sm:block border-[#2a2634] border-4 rounded-2xl ${
                  question?.spare ? "bg-behindButton" : ""
                } ${question?.custom ? "bg-mainText" : ""}`}
              >
                {question?.custom && (
                  <div className="text-center px-2 text-dark rounded-lg text-sm lg:text-xl">
                    فئة خاصة
                  </div>
                )}
                {question?.spare && (
                  <div className="text-center px-2 text-white rounded-lg text-sm lg:text-xl">
                    وسيلة مساعدة
                  </div>
                )}
                <div className="text-center p-1 px-4 lg:p-2 bg-[#2a2634] text-white rounded-lg text-sm lg:text-xl ">
                  {question?.spare
                    ? game?.teams?.find((team) => team.currentTurn).name
                    : modeName}
                </div>
              </div>
            </div>

            {/* Center Section (Timer Controls) */}
            <div className="w-6/12 flex justify-center items-center">
              <div className="p-2 px-8  bg-[#2a2634] text-white rounded-full flex gap-14 text-lg lg:text-3xl">
                <button onClick={() => (isRunning ? pause() : start())}>
                  {isRunning ? <FaPause /> : <FaPlay />}
                </button>
                <h1 className="text-center">
                  {minutes < 10 && "0"}
                  {minutes}:{seconds < 10 && "0"}
                  {seconds}
                </h1>
                <button onClick={() => reset()}>
                  <FaClockRotateLeft />
                </button>
              </div>
            </div>

            {/* Right Section */}
            <div className="w-1/3 flex justify-center items-center">
              <div className="hidden sm:block border-[#2a2634] border-4 rounded-2xl">
                <div className="text-right p-1 px-4 lg:p-2 bg-[#2a2634] text-white rounded-lg text-sm lg:text-xl flex gap-1">
                  <div>نقطة</div>
                  <div>{question?.points}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-0   transform translate-x-1/2 translate-y-1/2 ">
            <button
              className="bg-red-700 rounded-full hover:bg-red-800 text-white font-bold py-3 px-4 md:px-10 text-sm md:text-xl"
              onClick={() => {
                setShowWhoAnswered(true);
              }}
            >
              منو جاوب
            </button>
          </div>
          <div className="absolute  bottom-0   transform -translate-x-1/2 translate-y-1/2 right-0  text-white rounded-lg  text-sm lg:text-xl ">
            <button
              className="bg-green-700 rounded-full hover:bg-green-800 text-white font-bold py-3 px-4 md:px-10 text-sm md:text-xl"
              onClick={() => {
                setShowAnswer(false);
              }}
            >
              السؤال
            </button>
          </div>
        </div>
        <div className=" portrait:h-1/4 portrait:w-full  h-1/4 w-full  sm:w-1/4 sm:h-full flex-row sm:flex-col portrait:flex-row flex justify-center items-center text-white text-4xl sm:pl-4 sm:gap-2 portrait:pt-6 pt-6 sm:pt-0">
          {game?.teams.map((team, index) => (
            <div
              className="w-1/4 h-full sm:w-full sm:h-1/4 px-[1px] lg:p-2"
              key={index}
            >
              <div
                className={`portrait:flex-col  w-full h-full flex flex-col sm:flex-row justify-center items-center`}
              >
                <div className="h-1/2 w-full sm:w-2/3 sm:h-full text-sm md:text-lg lg:text-xl font-bold ">
                  <div
                    className={`h-1/2 flex items-center justify-center overflow-hidden bg-[#00009e] rounded-full  ${
                      team.currentTurn === true && "border-4 border-gray-400"
                    }`}
                  >
                    <h1 className="text-white text-center">{team?.name}</h1>
                  </div>
                  <div className="portrait:w-full portrait:h-1/2 w-full h-1/2 flex justify-center items-center p-1 gap-1 lg:gap-3">
                    {team?.powerUps.map((powerUp, index) => (
                      <button
                        disabled
                        className="rounded-full border-[4px] border-[#00009e] text-[#00009e]   p-1   lg:p-2  text-sm  md:text-lg xl:text-xl disabled:border-gray-400  disabled:text-gray-400"
                        key={index}
                      >
                        {powerUp?.name === "one" ? (
                          <FaMoneyBillTransfer />
                        ) : powerUp?.name === "two" ? (
                          <HiPhone />
                        ) : (
                          <TbSquareHalf />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {fullScreen && (
        <button
          onClick={() => {
            setFullScreen(false);
          }}
          className="absolute h-[100vh] w-[100vw] bg-[#00000090]"
        >
          <img
            src={question?.answerImage}
            alt={question?.answer}
            className="h-full w-full object-contain"
          />
        </button>
      )}
    </>
  );
};

export default SJAnswer;
