// DragAndDropImage.jsx
import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

/**
 *
 * @param {Function} onFileSelected - A callback receiving the File object.
 * @param {string} initialPreview - Optional existing image URL for preview.
 * @param {string} label - Text displayed in the drop area.
 */
export default function DragAndDropImage({
  shouldClear,
  setShouldClear,
  onFileSelected,
  initialPreview = "",
  label = "اسحب وأسقط أو ألصق صورة هنا",
}) {
  const [previewUrl, setPreviewUrl] = useState(initialPreview);
  const [isHovered, setIsHovered] = useState(false);

  // React Dropzone "onDrop" callback
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (file.type.startsWith("image/")) {
          setPreviewUrl(URL.createObjectURL(file));
          onFileSelected(file);
        } else {
          toast.error("Only image files are allowed.");
        }
      }
    },
    [onFileSelected]
  );

  // Initialize Dropzone hooks
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: { "image/*": [] },
  });

  // Handle image paste
  const handlePaste = (event) => {
    const { clipboardData } = event;
    if (!clipboardData) return;

    if (clipboardData.files && clipboardData.files.length > 0) {
      const file = clipboardData.files[0];
      if (file.type.startsWith("image/")) {
        setPreviewUrl(URL.createObjectURL(file));
        onFileSelected(file);
      } else {
        toast.error("Only image files are allowed.");
      }
    }
  };

  // Attach global paste listener when hovered
  useEffect(() => {
    const handleGlobalPaste = (event) => {
      if (!isHovered) return; // Only handle paste when hovered

      handlePaste(event);
    };

    if (isHovered) {
      document.addEventListener("paste", handleGlobalPaste);
    } else {
      document.removeEventListener("paste", handleGlobalPaste);
    }

    // Cleanup
    return () => {
      document.removeEventListener("paste", handleGlobalPaste);
    };
    // eslint-disable-next-line
  }, [isHovered]);

  // Clear preview when shouldClear changes
  useEffect(() => {
    if (shouldClear) {
      setPreviewUrl("");
      setShouldClear(false);
    }
    // eslint-disable-next-line
  }, [shouldClear]);

  return (
    <div
      {...getRootProps()}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        border: isHovered
          ? "2px solid #007bff" // Change border color on hover
          : isDragActive
          ? "2px solid #28a745" // Green when dragging
          : "2px dashed #aaa", // Default border
        borderRadius: 10,
        padding: 16,
        textAlign: "center",
        cursor: "pointer",
        marginBottom: 20,
        background: isHovered ? "#e6f7ff" : "#f9f9f9", // Light blue background on hover
        minHeight: 150,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "all 0.3s ease",
      }}
    >
      <input {...getInputProps()} />
      {previewUrl ? (
        <img
          src={previewUrl}
          alt="preview"
          style={{
            maxHeight: 220,
            objectFit: "contain",
            borderRadius: 8,
          }}
        />
      ) : isDragActive ? (
        <p style={{ color: "#28a745", fontWeight: "bold" }}>
          افلت الصورة هنا...
        </p>
      ) : (
        <p style={{ color: "#666", fontSize: "1rem" }}>
          {label}
          <br /> (انقر، اسحب، أو الصق)
        </p>
      )}

      {/* Overlay Message on Hover */}
      {isHovered && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 123, 255, 0.1)", // Semi-transparent overlay
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none", // Allow underlying interactions
            borderRadius: 10,
            transition: "background-color 0.3s ease",
          }}
        >
          <p style={{ color: "#007bff", fontWeight: "bold" }}>
            يمكنك لصق الصورة
          </p>
        </div>
      )}
    </div>
  );
}
