// /api/custom-mode.js (or wherever you define your API calls)

import { instance } from ".."; // Your axios instance

export const createCutsomQuestion = async (
  modeId,
  webPassword,
  questionInfo
) => {
  const formData = new FormData();

  // Append the text fields
  formData.append("webPassword", webPassword);
  formData.append("question", questionInfo.question);
  formData.append("answer", questionInfo.answer);
  formData.append("points", questionInfo.points);

  // If you have additional fields (marginText, type = "Text", etc.), append those too:
  // formData.append("marginText", questionInfo.marginText ?? "");

  // Append the files (if any)
  if (questionInfo.questionFile) {
    formData.append("questionImage", questionInfo.questionFile);
  }
  if (questionInfo.answerFile) {
    formData.append("answerImage", questionInfo.answerFile);
  }

  // Now submit using axios
  const response = await instance.post(
    `/api/custom-mode/add-web-question/${modeId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};
