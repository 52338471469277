const textSizeBasedOnLength = (text) => {
  if (text?.length < 100) {
    return "text-xl lg:text-3xl";
  } else if (text?.length < 150) {
    return "text-lg lg:text-2xl";
  } else if (text?.length < 200) {
    return "lg:text-xl";
  } else {
    return "text-sm lg:text-lg";
  }
};

export default textSizeBasedOnLength;
