import axios from "axios";

const BASE_URL = "https://tarbaa-server-pro-7e22029d9d12.herokuapp.com";
// const BASE_URL = "http://192.168.3.127:8000";
// const BASE_URL = "http://172.20.10.10:8080";
// const BASE_URL = "http://localhost:8080";

const instance = axios.create({
  baseURL: BASE_URL,
});

export { BASE_URL, instance };
