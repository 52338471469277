// src/utils/encryption.js
import CryptoJS from "crypto-js";

// Define a secret key for encryption and decryption
const SECRET_KEY = "tarbaakw"; // **Important:** Keep this key secure

/**
 * Encrypts data using AES encryption.
 * @param {Object} data - The data to encrypt.
 * @returns {string} - The encrypted ciphertext.
 */
export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

/**
 * Decrypts AES-encrypted data. If the data is not encrypted,
 * it attempts to parse it directly as JSON.
 * @param {string} ciphertext - The data to decrypt or parse.
 * @returns {Object|null} - The decrypted or parsed data, or null if parsing fails.
 */
export const decryptData = (ciphertext) => {
  // CryptoJS encrypted strings start with "U2FsdGVkX1"
  const isEncrypted =
    typeof ciphertext === "string" && ciphertext.startsWith("U2FsdGVkX1");

  if (isEncrypted) {
    try {
      const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData ? JSON.parse(decryptedData) : null;
    } catch (error) {
      console.error("Decryption failed:", error);
      return null;
    }
  } else {
    try {
      return JSON.parse(ciphertext);
    } catch (error) {
      console.error("JSON parsing failed:", error);
      return null;
    }
  }
};
