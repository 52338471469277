// src/components/tarbaa/WhoAnswered.jsx
import React from "react";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { HiPhone } from "react-icons/hi2";
import { TbSquareHalf } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

// Import encryption utilities
import { encryptData } from "../../funcs/encryption";
import { questionShown } from "../../api/game";
import { useMutation } from "@tanstack/react-query";

const WhoAnswered = ({ game, question, setShowWhoAnswered }) => {
  const navigate = useNavigate();

  const questionInGame = question?.custom
    ? game.modes
        .find((mode) =>
          mode.questions.find(
            (q) => (q.question?._id || q?.customQuestion?._id) === question?._id
          )
        )
        .questions.find(
          (q) => (q?.question?._id || q?.customQuestion?._id) === question?._id
        ).customQuestion
    : game.modes
        .find((mode) =>
          mode.questions.find(
            (q) => (q.question?._id || q?.customQuestion?._id) === question?._id
          )
        )
        .questions.find(
          (q) => (q?.question?._id || q?.customQuestion?._id) === question?._id
        ).question;

  const checkIfAllQuestionsAnswered = () => {
    let answered = true;
    game?.modes?.forEach((mode) => {
      mode.questions.forEach((q) => {
        if (!q.question?.answered && !q.customQuestion?.answered) {
          answered = false;
        }
      });
    });
    return answered;
  };

  const nextTurn = () => {
    const currentTurnIndex = game.teams.findIndex((team) => team.currentTurn);
    const nextTurnIndex =
      currentTurnIndex === game.teams.length - 1 ? 0 : currentTurnIndex + 1;

    const updatedTeams = game.teams.map((team, index) => ({
      ...team,
      currentTurn: index === nextTurnIndex,
    }));

    // Encrypt and save updated game data
    localStorage.setItem("game", encryptData({ ...game, teams: updatedTeams }));
  };

  const { mutate } = useMutation({
    mutationKey: ["questionShown"],
    mutationFn: ({ questionId, isAnswered }) =>
      questionShown(questionId, isAnswered),
  });

  const handleQuestionShown = (hasBeenAnswered) => {
    mutate({
      questionId: questionInGame?._id,
      isAnswered: hasBeenAnswered,
    });
  };

  return (
    <>
      <div className="w-full h-[90vh] flex flex-col sm:flex-row pl-10 pr-2 py-10 lg:py-20">
        <div className="relative h-3/4 w-full sm:w-5/6 sm:h-full text-xl lg:text-3xl font-bold text-dark border-8 border-behindButton rounded-[50px]">
          <div className="h-full w-full flex justify-center items-center p-2 px-4 lg:px-10 text-white  ">
            <div className="flex-wrap flex justify-center items-center gap-4 ">
              {game?.teams.map((team, index) => (
                <button
                  onClick={() => {
                    if (question?.points > 0) {
                      game.teams[index].points += question?.points;
                      questionInGame.answered = true;
                      // Encrypt and save updated game data
                      localStorage.setItem("game", encryptData({ ...game }));
                    }
                    handleQuestionShown(true);
                    nextTurn();
                    if (checkIfAllQuestionsAnswered()) {
                      navigate(`/${game?.codeInfo?.code || "old"}/end`);
                    } else {
                      navigate(`/${game?.codeInfo?.code || "old"}`);
                    }
                  }}
                  className="w-1/3 h-1/4 p-2 lg:p-4 bg-secondaryButton rounded-2xl hover:bg-secondaryButtonShadow flex flex-row justify-center items-center"
                  key={index}
                >
                  <div className="w-1/4 h-full flex justify-center items-center">
                    <div className="w-full aspect-square overflow-hidden rounded-full">
                      <img
                        src={team?.image?.image}
                        alt={team?.name}
                        className="w-full h-full object-cover "
                      />
                    </div>
                  </div>
                  <div className="h-full w-3/4 text-sm md:text-xl lg:text-2xl font-bold flex justify-center items-center overflow-hidden">
                    <h1 className="text-white text-center">{team?.name}</h1>
                  </div>
                </button>
              ))}
              <button
                onClick={() => {
                  questionInGame.answered = true;
                  // Encrypt and save updated game data
                  localStorage.setItem("game", encryptData(game));
                  nextTurn();
                  handleQuestionShown(false);
                  if (checkIfAllQuestionsAnswered()) {
                    navigate(`/${game?.codeInfo?.code || "old"}/end`);
                  } else {
                    navigate(`/${game?.codeInfo?.code || "old"}`);
                  }
                }}
                className="w-1/3 h-1/4  p-2 lg:p-4 bg-secondaryButton rounded-2xl hover:bg-secondaryButtonShadow"
              >
                محد جاوب
              </button>
            </div>
          </div>

          <div className="absolute  bottom-0   transform -translate-x-1/2 translate-y-1/2 right-0  text-white rounded-lg  text-sm lg:text-xl ">
            <button
              className="bg-mainButton hover:bg-mainButtonShadow text-white font-bold py-3 px-4 md:px-10 border-b-8 border-mainButtonShadow hover:border-mainButtonShadow rounded-md text-sm md:text-xl"
              onClick={() => {
                setShowWhoAnswered(false);
              }}
            >
              الجواب
            </button>
          </div>
        </div>
        <div className="h-1/4 w-full sm:w-1/4 sm:h-full flex-row sm:flex-col flex justify-center items-center text-white text-4xl pl-4 gap-2 pt-4 sm:pt-0">
          {game?.teams.map((team, index) => (
            <div
              className="w-1/4 h-full sm:w-full sm:h-1/4 px-[1px] lg:p-2"
              key={index}
            >
              <div
                className={`w-full h-full flex flex-col sm:flex-row justify-center items-center bg-[#dd9c6e] rounded-lg ${
                  team.currentTurn === true && "border-4 border-dark"
                }`}
              >
                <div className="w-full h-1/2 sm:w-1/3 sm:h-full flex justify-center items-center p-1 sm:p-2">
                  <div className="h-full aspect-square rounded-full overflow-hidden flex justify-center items-center">
                    <div className="w-full aspect-square rounded-full overflow-hidden ">
                      <img
                        src={team?.image?.image}
                        alt={team?.name}
                        className="w-full h-full object-cover "
                      />
                    </div>
                  </div>
                </div>
                <div className="h-1/2 w-full sm:w-2/3 sm:h-full text-sm md:text-xl lg:text-2xl font-bold ">
                  <div className="h-1/2 flex items-center justify-center overflow-hidden">
                    <h1 className="text-white text-center">{team?.name}</h1>
                  </div>
                  <div className="w-full h-1/2 flex justify-center items-center p-2 gap-1 md:gap-4">
                    {team?.powerUps.map((powerUp, index) => (
                      <button
                        disabled
                        className="bg-mainButtonShadow hover:bg-mainButton rounded-md md:rounded-lg p-1 md:p-2 text-sm md:text-lg xl:text-2xl disabled:bg-[#94674690] disabled:text-[#ffffff90]"
                        key={index}
                      >
                        {powerUp?.name === "one" ? (
                          <FaMoneyBillTransfer />
                        ) : powerUp?.name === "two" ? (
                          <HiPhone />
                        ) : (
                          <TbSquareHalf />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default WhoAnswered;
